<script lang="ts" setup>
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import fr from 'dayjs/locale/fr';
import { useI18n } from 'vue-i18n';
import { useScroll } from '@vueuse/core';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

dayjs.extend(timezone);
dayjs.extend(utc);

// Stores
dayjs.locale({
  ...fr,
  weekStart: siteStore().getProductionStartWeekday(),
});

// Composables
const { t } = useI18n();

// Props & Emit
const props = defineProps<{
  placeholder?: string;
  range: { start: Date | null; end: Date | null };
}>();
const dateRange = ref<{ start: Date | null; end: Date | null }>({ start: null, end: null });
const emit = defineEmits(['update:range']);

// Data
const calendarStart = ref<any>(null);
const calendarEnd = ref<any>(null);
const modal = ref(false);
const shortcut = ref<HTMLElement | null>(null);
const { y } = useScroll(shortcut);

// Computed
const buttonTitle = computed(() => {
  if (props.placeholder) return props.placeholder;
  if (!props.range?.start || !props.range?.end) return props.placeholder ?? t('global.select_a_date');
  return `${dayjs(props.range.start).format('DD/MM/YYYY')} - ${dayjs(props.range.end).format('DD/MM/YYYY')}`;
});

const shortcuts = computed(() => {
  // Values
  const start = dayjs(dateRange.value.start);
  const end = dayjs(dateRange.value.end);

  return [
    {
      label: t('global.date_range.last_x_days', { value: 7 }),
      start: dayjs().subtract(6, 'day').toDate(),
      end: dayjs().toDate(),
      selected: start.isSame(dayjs().subtract(6, 'day'), 'day') && end.isSame(dayjs().toDate(), 'day'),
    },
    {
      label: t('global.date_range.last_week'),
      start: dayjs().subtract(1, 'week').startOf('week').toDate(),
      end: dayjs().subtract(1, 'week').endOf('week').toDate(),
      selected:
        start.isSame(dayjs(dayjs().subtract(1, 'week').startOf('week')), 'day') &&
        end.isSame(dayjs().subtract(1, 'week').endOf('week').toDate(), 'day'),
    },
    {
      label: t('global.date_range.current_month'),
      start: dayjs().startOf('month').toDate(),
      end: dayjs().toDate(),
      selected: start.isSame(dayjs().startOf('month').toDate(), 'day') && end.isSame(dayjs().toDate(), 'day'),
    },
    {
      label: t('global.date_range.last_month'),
      start: dayjs().subtract(1, 'month').startOf('month').toDate(),
      end: dayjs().subtract(1, 'month').endOf('month').toDate(),
      selected:
        start.isSame(dayjs().subtract(1, 'month').startOf('month'), 'day') &&
        end.isSame(dayjs().subtract(1, 'month').endOf('month').toDate(), 'day'),
    },
    {
      label: t('global.date_range.last_x_months', { value: 3 }),
      start: dayjs().subtract(3, 'month').startOf('month').toDate(),
      end: dayjs().subtract(1, 'month').endOf('month').toDate(),
      selected:
        start.isSame(dayjs().subtract(3, 'month').startOf('month'), 'day') &&
        end.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day'),
    },
    {
      label: t('global.date_range.last_x_months', { value: 6 }),
      start: dayjs().subtract(6, 'month').startOf('month').toDate(),
      end: dayjs().subtract(1, 'month').endOf('month').toDate(),
      selected:
        start.isSame(dayjs().subtract(6, 'month').startOf('month'), 'day') &&
        end.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day'),
    },
    {
      label: t('global.date_range.last_x_months', { value: 12 }),
      start: dayjs().subtract(12, 'month').startOf('month').toDate(),
      end: dayjs().subtract(1, 'month').endOf('month').toDate(),
      selected:
        start.isSame(dayjs().subtract(12, 'month').startOf('month'), 'day') &&
        end.isSame(dayjs().subtract(1, 'month').endOf('month'), 'day'),
    },
    {
      label: t('global.date_range.current_year'),
      start: dayjs().startOf('year').toDate(),
      end: dayjs().toDate(),
      selected: start.isSame(dayjs(dayjs().startOf('year').toDate()), 'day') && end.isSame(dayjs(dayjs().toDate()), 'day'),
    },
    {
      label: t('global.date_range.last_year'),
      start: dayjs().subtract(1, 'year').startOf('year').toDate(),
      end: dayjs().subtract(1, 'year').endOf('year').toDate(),
      selected:
        start.isSame(dayjs(dayjs().subtract(1, 'year').startOf('year')), 'day') &&
        end.isSame(dayjs().subtract(1, 'year').endOf('year').toDate(), 'day'),
    },
  ];
});

// Methods
const shortcutClick = (shortcut: { label: string; start: Date; end: Date }) => {
  // Start
  dateRange.value.start = shortcut.start;
  calendarStart.value.move(shortcut.start);

  // End
  dateRange.value.end = shortcut.end;
  calendarEnd.value.move(shortcut.end);
};

const scrollDown = () => {
  y.value += 46;
};

const scrollUp = () => {
  y.value -= 46;
};

const submit = () => {
  emit('update:range', { start: dateRange.value.start, end: dateRange.value.end });
  modal.value = false;
};

// Watchers
watchEffect(() => {
  if (!props?.range?.start || !props?.range?.end) return;
  dateRange.value.start = props.range.start ?? null;
  dateRange.value.end = props.range.end ?? null;
});
</script>
<template>
  <ui-button left-icon="Calendar" size="sm" color="secondary" @click="modal = !modal">
    <slot /> {{ $slots.default ? ': ' : '' }}{{ buttonTitle }}
  </ui-button>

  <ui-modal v-model="modal" :title="$t('global.select_a_period')" width="900">
    <div class="grid grid-cols-3 gap-4">
      <div class="flex flex-col bg-gray-50 p-4 pb-8 rounded-md max-h-[350px]">
        <label class="font-medium text-sm mb-[8px] block text-left text-gray-900">
          {{ $t('global.shortcuts') }}
        </label>
        <ui-button color="secondary" class="mb-2" left-icon="ChevronUp" @click="scrollUp()" />
        <div ref="shortcut" class="overflow-y-scroll">
          <div class="space-y-2">
            <div
              v-for="shortcut in shortcuts"
              :key="shortcut.label"
              class="w-full border p-2 bg-white text-sm text-center rounded-md cursor-pointer"
              :class="shortcut.selected ? 'border-green-500 ring-inset' : ''"
              @click="shortcutClick(shortcut)"
            >
              {{ shortcut.label }}
            </div>
          </div>
        </div>
        <ui-button color="secondary" class="mt-2" left-icon="ChevronDown" @click="scrollDown()" />
      </div>
      <div class="flex flex-col bg-gray-50 p-4 pb-8 rounded-md relative">
        <label class="font-medium text-sm mb-[8px] block text-left text-gray-900">
          {{ $t('global.start_date') }}
          <span class="text-red-500">*</span>
        </label>
        <DatePicker
          ref="calendarStart"
          v-model="dateRange.start"
          color="green"
          :disabled-dates="[{ start: dayjs().add(1, 'day').toDate() }, { start: dayjs(dateRange.end).add(1, 'day').toDate() }]"
        />
        <span v-if="!dateRange.start" class="absolute right-4 bottom-2 text-red-500 text-xs">
          {{ $t('global.form.you_need_to_select_a_date') }}
        </span>
      </div>
      <div class="flex flex-col bg-gray-50 p-4 pb-8 rounded-md relative">
        <label class="font-medium text-sm mb-[8px] block text-left text-gray-900">
          {{ $t('global.end_date') }}
          <span class="text-red-500">*</span>
        </label>
        <DatePicker
          ref="calendarEnd"
          v-model="dateRange.end"
          color="green"
          :disabled-dates="[{ start: dayjs().add(1, 'day').toDate() }, { end: dayjs(dateRange.start).subtract(1, 'day').toDate() }]"
        />
        <span v-if="!dateRange.end" class="absolute right-4 bottom-2 text-red-500 text-xs">
          {{ $t('global.form.you_need_to_select_a_date') }}
        </span>
      </div>
    </div>

    <template #footer>
      <ui-button color="secondary" @click="modal = false">{{ $t('global.cancel') }}</ui-button>
      <ui-button color="primary" :disabled="!dateRange.start || !dateRange.end" @click="submit()">
        {{ $t('global.validate') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
